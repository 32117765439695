import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { CreatePaybackComponent } from '../popup/create-payback/create-payback.component';
import { CreatePackageComponent } from '../popup/create-package/create-package.component';
import { CreateVolumePackageComponent } from '../popup/create-volume-package/create-volume-package.component';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { CreateClaimModal } from '../../_models/createClaim';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs/internal/Subscription';
import { finalize } from 'rxjs/internal/operators/finalize';
import { TableModalComponent } from '../table-modal/table-modal.component';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { Location } from '@angular/common';
import * as moment from 'moment';
import _ from 'lodash';
import { ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Subject } from 'rxjs/internal/Subject';
import { DatePipe } from '@angular/common';
import { type } from 'os';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-create-claim',
    templateUrl: './create-claim.component.html',
    styleUrls: ['./create-claim.component.css']
})
export class CreateClaimComponent implements OnInit {
    domain = environment.ppa;
    claimData: CreateClaimModal;
    myControl = new FormControl();
    options: string[] = [];
    filteredOptions: any;
    selectedValue = null;
    @Output()
    createUsercontact = new EventEmitter<CreateClaimModal>();
    // horizontalPosition: MatSnackBarHorizontalPosition = 'start';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    url;
    auditorList: any;
    ccCode: any = '';
    claimStatus: any;
    claimType: any;
    recordID: any;
    pageID: any;
    claimDetails: any;
    claimDetailById: any;
    AllDropdowns: any;
    claimNumberBlank: any;
    appClaimNumber: any;
    dateAudited: any;
    auditYear: any;
    supplierName: any;
    sap_vendor: any;
    supplierNum: any;
    accountDepartment: any;
    files: any[] = [];
    fileData: any;
    fileUploaddata = false;
    showPrDetails = false;
    claimTypeIdentifier: any;

    fileUpload = false;
    fileName = '';
    uploadedFilesList = [];
    //DropDownValues
    AuditTypeDropdown = [];
    AuditorIdDropdown = [];
    claimHolderDrowdown = [];
    claimStatusDropdown = [];
    rootCauseDropdown = [];
    Format = [];
    currency = [];
    Action = [];
    areaOfResponsibility = [];
    conversionLikelihood = [];
    smasCatDropDown = [];
    conceptDropdown = [];
    scopeDropdown = [];
    deptNumDropDown = [];
    percentDone: number;
    uploadSuccess: boolean;
    uplodedFiles = [];
    showImgError = false;
    showLoader = false;
    selectedPRValues: any;
    selectedClaimType: any;
    username: any;
    multipleRecordIds: any;
    changeInputValue: Subject<any> = new Subject<any>();
    changeInputValueMX: Subject<any> = new Subject<any>();
    isDisabled = true;
    saveButton = 'block';
    currentDateTime;
    @ViewChild('myInput')
    myInputVariable: ElementRef;
    fileResonseClaimNumber: any;
    originalCLaimAmountfromSession: any;
    originalCLaimAmountfromSessionMX: any;
    agreementAmountMarkdown: any;
    taxIVAsessionMX: any;
    taxIEPSsessionMX: any;
    sidePRDetails = 'block';
    multipleRecordDetails: any;
    originalClaimAmt: any;
    adjustments: any;
    taxEps: any;
    taxIva: any;
    Total_Deduction: any;
    adjustedClaimAmt: any;

    constructor(private _location: Location, private router: Router, private _snackBar: MatSnackBar, private datePipe: DatePipe, private apiservice: apiService, public activatedRoute: ActivatedRoute, public dialog: MatDialog, private formBuilder: FormBuilder, private http: HttpClient) {
        this.claimData = new CreateClaimModal();
        this.claimData.APP_CLAIM_NUMBER = null;
        this.claimData.DATE_AUDITED = null;
        this.claimData.Audit_year = null;
        this.claimData.Auditor_Id = '';
        this.claimData.Claim_Type = '';
        this.claimData.Claim_Holder = '';
        this.claimData.Claim_Status = '';
        this.claimData.Root_Cause = '';
        this.claimData.Claim_Reason = '';
        this.claimData.WM_Claim_Reason = '';
        //   this.claimData.CLAIM_ID = null;
        // this.claimData.WMCLAIM_ID = null;
        this.claimData.Claim_Explanation = '';
        this.claimData.Buyer_Id = null;
        this.claimData.Supplier_Num = '';
        this.claimData.Buyer_Name = '';
        this.claimData.Supplier_Name = '';
        this.claimData.DMM_Id = '';
        this.claimData.DMM_Name = '';
        this.claimData.Supplier_Address = '';
        this.claimData.Format = '';
        this.claimData.scope = '';
        this.claimData.concept = '';
        this.claimData.Original_Claim_Amt = null;
        this.claimData.Currency = '';
        this.claimData.action = '';
        this.claimData.Adjustments = null;
        this.claimData.Tax_IVA = null;
        this.claimData.Tax_EPS = null;
        this.claimData.Adjusted_Claim_Amt = null;
        this.claimData.Supplier_Remark = '';
        this.claimData.Auditor_Findings = '';
        this.claimData.Dept_Num = '';
        this.claimData.Area_of_Responsibility = '';
        this.claimData.Time_Period = '';
        this.claimData.Conversion_Likelihood = '';
        this.claimData.SAMS_Cat = '';
        this.claimData.Additional_Note = '';
        this.claimData.UPLOAD_FILE = "";
        this.claimData.country = '';
        this.claimData.creation_by = '';
        this.claimData.user_id = '';
        //if (this.pageID == 'CL') {
        this.changeInputValue.pipe(debounceTime(500)).subscribe((obj) => {
            console.log(obj);
            this.taxCalulate(obj.claimAmount, obj.adjustment);
            this.adjestclaimAmt(obj.claimAmount, obj.adjustment, obj.taxIVA, obj.taxEPS);
        });
        //}

        // setTimeout(function(){ this.changeInputValueMX.pipe(debounceTime(2000)).subscribe((obj) => {

        //   this.adjestclaimAmtMX(obj.claimAmount, obj.adjustment, obj.taxIVA, obj.taxEPS);

        // });},3000)
        this.currentDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }

    addForm: FormGroup = new FormGroup({
        Auditor_Id: new FormControl('', Validators.required),
        Claim_Type: new FormControl('', Validators.required),
        Claim_Holder: new FormControl('auditor who is handling claim'),
        Claim_Status: new FormControl('Nueva Deducción', Validators.required),
        Root_Cause: new FormControl('', Validators.required),
        APP_CLAIM_NUMBER: new FormControl(''),
        Claim_Reason: new FormControl(''),
        WM_Claim_Reason: new FormControl(''),
        //  CLAIM_ID: new FormControl({ value: '', disabled: true }),
        //  WMCLAIM_ID: new FormControl({ value: '', disabled: true }),
        Audit_year: new FormControl(null),
        Claim_Explanation: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
        Buyer_Id: new FormControl(''),
        Supplier_Num: new FormControl('', Validators.required),
        Buyer_Name: new FormControl(''),
        Supplier_Name: new FormControl({ value: '', disabled: true }),

        DMM_Id: new FormControl(''),
        DMM_Name: new FormControl(''),

        Supplier_Address: new FormControl(''),
        scope: new FormControl('PROJECT', Validators.required),
        concept: new FormControl('', Validators.required),
        Format: new FormControl('LIDER', Validators.required),
        Original_Claim_Amt: new FormControl('', Validators.required),
        Currency: new FormControl('', Validators.required),
        action: new FormControl('', Validators.required),
        Adjustments: this.ccCode == 'CL' ? new FormControl(0.0, Validators.required) : new FormControl(0.0),
        Tax_IVA: this.ccCode == 'CL' ? new FormControl(null) : new FormControl(null),
        Tax_EPS: this.ccCode == 'CL' ? new FormControl(0) : new FormControl(0),
        Adjusted_Claim_Amt: new FormControl(0),
        Supplier_Remark: new FormControl('', [Validators.maxLength(1000)]),
        Auditor_Findings: new FormControl('', [Validators.maxLength(1000)]),
        Dept_Num: new FormControl('', Validators.required),
        Area_of_Responsibility: new FormControl(''),
        Time_Period: new FormControl('', Validators.required),
        Conversion_Likelihood: new FormControl('MEDIUM', Validators.required),
        SAMS_Cat: new FormControl(''),
        Additional_Note: new FormControl('', [Validators.maxLength(1000)]),
        UPLOAD_FILE: new FormControl('')
    });

    ngOnInit() {
        this.multipleRecordIds = this.apiservice.getSession('recordIDs');
        this.originalCLaimAmountfromSession = this.apiservice.getSession('originalCLaimAmount');
        this.originalCLaimAmountfromSessionMX = this.apiservice.getSession('originalCLaimAmountMX');
        this.taxIVAsessionMX = this.apiservice.getSession('taxIVA');
        this.taxIEPSsessionMX = this.apiservice.getSession('taxIEPS');
        // this.agreementAmountMarkdown =  this.apiservice.getSession('agreementAmount');

        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;

        this.ccCode = this.activatedRoute.snapshot.params['CL'];
        this.pageID = this.activatedRoute.snapshot.params['ID'];
        this.getAllClaimData(this.ccCode);
        this.getAuditorList();
        console.log("pageID ", this.pageID)
        this.recordID = this.activatedRoute.snapshot.params['recordID'];

        this.chnageStatus(this.ccCode);
        this.loadAuditTypeValues(this.ccCode);
        // this.Tax_EPSDisable(this.ccCode);
        if (this.recordID != undefined) {
            this.selectedPRDetails(this.pageID, this.recordID, this.ccCode);
        } else {
            this.claimDetails = [];
            this.appClaimNumber = '';
            this.dateAudited = '';
            this.auditYear = '';
        }
        this.getAllDropdownValues(this.ccCode);
        this.getDeptNumDropDown();

        this.hideSidePRDetails();
        this.getClaimsnumber(this.pageID, this.recordID, this.ccCode);
        this.multipleClaimsDetails(this.multipleRecordIds);
    }

    multipleClaimsDetails(multpleRecordIds) {
        console.log(multpleRecordIds);
        if (multpleRecordIds != null && multpleRecordIds != undefined && multpleRecordIds != '') {
            let recordIDS = {
                RECORD_ID: this.multipleRecordIds.replace(/[\[\]']+/g, '')
            };
            this.apiservice.getmultiplecaimDetails(this.pageID, recordIDS, this.ccCode).subscribe((response) => {
                this.multipleRecordDetails = response;
            });
        }
    }

    cancelClaim() {
        if (this.pageID == undefined) {
            // this._location.back();
            //  this.router.navigate(['/pages/managePR/' + this.ccCode + '/VOLUMES']);
            this.router.navigate(['dashboard/' + '/' + this.ccCode]);
        } else {
            this.router.navigate(['/pages/managePR/' + this.ccCode + '/' + this.pageID]);
        }
    }
    get claimForm() {
        return this.addForm.controls;
    }

    getAuditorList() {
        this.apiservice.getAuditorList(this.pageID, this.ccCode).subscribe((response) => {
            this.auditorList = response;
        });
    }

    chnageStatus(ccCode) {
        this.apiservice.getAuditorStatusList(ccCode).subscribe((response) => {
            this.claimStatus = response;
        });
    }

    loadAuditTypeValues(CLCode) {
        this.apiservice.getAuditType(CLCode).subscribe((response) => {
            this.claimType = response;
        });
    }
    // supplierName:any;
    getCLaimType(selectedData) {
        if (this.ccCode == 'MX') {
            this.claimHolderValuesMX(selectedData);
        }
        else {
            this.claimHolderValues(selectedData);
        }

        // this.selectedClaimType = selectedData;
    }

    selectedPRDetails(pageID, recordID, ccCode) {
        this.uploadedFilesList = [];
        this.showLoader = true;
        this.apiservice.getClaimsDataByID(pageID, recordID, ccCode).subscribe((response) => {
            this.claimDetails = response;
            console.log("this.claimDetails ",this.claimDetails)
            this.showLoader = false;
            const claimDetails = response[0];
            this.showPrDetails = this.claimDetails.length != 0 ? true : false;
            if (ccCode == 'MX') {
                this.appClaimNumber = this.claimDetails.length > 0 && this.claimDetails[0].Claim_Number != null ? this.claimDetails[0].Claim_Number : '';
                if (this.pageID == 'Scan Allowances') {
                    this.addForm.get('Adjustments').setValue(this.claimDetails[0].Adjustments);
                }else if (this.pageID == 'New Stores' || this.pageID == 'Markdown') {
                    this.addForm.get('Adjustments').setValue(0);
                }
                this.addForm.get('Currency').setValue('MXN');
                this.addForm.get('action').setValue('Acuerdo fijo omitido');
                this.addForm.get('Tax_IVA').setValue(this.taxIVAsessionMX);
                this.addForm.get('Tax_EPS').setValue(this.taxIEPSsessionMX);
                this.addForm.get('Original_Claim_Amt').setValue(this.originalCLaimAmountfromSessionMX);
                console.log('this.originalCLaimAmountfromSessionMX', this.originalCLaimAmountfromSessionMX);
            } else {
                this.appClaimNumber = this.claimDetails.length > 0 && this.claimDetails[0].APP_CLAIM_NUMBER != null ? this.claimDetails[0].APP_CLAIM_NUMBER : '';
                if (this.pageID == 'MISC') {
                    this.Total_Deduction = this.claimDetails[0].TOTAL_DEDUCTION;
                }
                this.addForm.get('Currency').setValue('CLP');
                this.addForm.get('action').setValue('Acuerdo fijo omitido');
            }
            
            this.auditYear = this.claimDetails.length > 0 ? this.claimDetails[0].AUDIT_YEAR : '';
            this.supplierName = this.claimDetails[0].SUPPLIER_NAME;
            this.sap_vendor = this.claimDetails[0].SAP_VENDOR;
            this.supplierNum = this.claimDetails[0].SUPPLIER_NAME != undefined ? this.claimDetails[0].SUPPLIER_NAME : '';
            this.accountDepartment = this.claimDetails[0].DEPT;

            this.addForm.get('Claim_Holder').setValue('auditor who is handling claim');
            this.addForm.get('Time_Period').setValue(this.claimDetails[0].AUDIT_YEAR);
            this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPARTMENT);
            this.supplierName = this.claimDetails[0].VENDOR_NAME;
            this.addForm.get('Supplier_Name').setValue(this.claimDetails[0].VENDOR_NAME);
            this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].VENDOR_NBR);
            this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);

            if (this.ccCode == 'CL') {
                if (this.pageID == 'NEW-STORE') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR_ID);
                    this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].SUPPLIER_NUM);
                    this.addForm.get('Claim_Type').setValue('Apertura');
                    this.claimHolderValues('Apertura');
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    this.addForm.get('Format').setValue(this.claimDetails[0].FORMAT);
                    // this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.claimDetails[0].AMOUNT_DUE));
                    this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));

                    this.addForm.get('Root_Cause').setValue('Comercial calculó cobro de Aperturas con un APC no vigente');
                    this.addForm.get('concept').setValue('55-BONIFICACION POR APERTURA DE TIENDA');
                } else if (this.pageID == 'MISC') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR_ID);
                    this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].SUPPLIER_NUM);
                    this.addForm.get('Claim_Type').setValue('Apertura');
                    this.claimHolderValues('Apertura');
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    this.addForm.get('Format').setValue('819  LIDER');
                    // this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.claimDetails[0].AMOUNT_DUE));
                    this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));
                    this.addForm.get('Root_Cause').setValue('Comercial calculó cobro de Aperturas con un APC no vigente');
                } else if (this.pageID == 'UNDED-COOPS') {
                    this.addForm.get('Claim_Type').setValue('Acuerdos por Monto Fijo');
                    this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].VENDOR_NBR);
                    this.claimHolderValues('Acuerdos por Monto Fijo');
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    this.addForm.get('Format').setValue('LIDER');
                    this.addForm.get('concept').setValue('527-BONIFICACION POR VOLUMEN  SOBRE VENTAS');
                    // this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.claimDetails[0].AMOUNT));
                    this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));
                    this.addForm.get('Root_Cause').setValue('Comercial no ingresó la deducción por Acuerdo de Monto Fijo en GLAS');
                } else if (this.pageID == 'VOLUMES') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR_ID);
                    this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].SUPPLIER_NUM);
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].ACCOUNT_DEPARTMENT);
                    this.addForm.get('Format').setValue('819  LIDER');
                    // this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.claimDetails[0].DEBITED_AMOUNT));
                    this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));

                    if (this.claimDetails[0].ALLOW_TYPE.toUpperCase() == 'FEE') {
                        this.addForm.get('Claim_Type').setValue('Descuentos por Distribución Centralizada');
                        this.claimHolderValues('Descuentos por Distribución Centralizada');
                        this.addForm.get('Root_Cause').setValue('GLAS calculó incorrectamente el monto de descuento sobre el total de la base de referencia.');
                        this.addForm.get('concept').setValue('558-DESCUENTO ENTREGA CENTRALIZADA');
                    }
                    if (this.claimDetails[0].ALLOW_TYPE.toUpperCase() == 'LOGRO') {
                        this.addForm.get('Claim_Type').setValue('Descuentos por volumen sobre las compras');
                        this.claimHolderValues('Descuentos por volumen sobre las compras');
                        this.addForm.get('Root_Cause').setValue('GLAS calculó incorrectamente el monto de descuento sobre el total de la base de referencia.');
                        this.addForm.get('concept').setValue('527-BONIFICACION POR VOLUMEN  SOBRE VENTAS');
                    }
                    if (this.claimDetails[0].ALLOW_TYPE.toUpperCase() == 'REPO') {
                        this.addForm.get('Claim_Type').setValue('Descuentos por volumen sobre las ventas');
                        this.claimHolderValues('Descuentos por volumen sobre las ventas');
                        this.addForm.get('Root_Cause').setValue('CAS calculó incorrectamente el monto de descuento sobre el total de la base de referencia.');
                        this.addForm.get('concept').setValue('527-BONIFICACION POR VOLUMEN  SOBRE VENTAS');
                    }
                    if (this.claimDetails[0].ALLOW_TYPE.toUpperCase() == 'MERMA') {
                        this.addForm.get('Claim_Type').setValue('Merma');
                        this.claimHolderValues('Merma');
                        this.addForm.get('Root_Cause').setValue('GLAS calculó incorrectamente el monto de descuento sobre el total de la base de referencia.');
                        this.addForm.get('concept').setValue('561-MERMA');
                    }
                }
                const caluTax = this.taxCalulate(Number(this.addForm.value.Original_Claim_Amt), Number(this.addForm.value.Adjustments));
                const adjestclaimAmtnu = this.adjestclaimAmt(Number(this.addForm.value.Original_Claim_Amt), Number(this.addForm.value.Adjustments), Number(this.addForm.value.Tax_IVA), Number(this.addForm.value.Tax_EPS));

                this.addForm.get('Tax_IVA').setValue(caluTax);
            } else {
                if (this.pageID == 'Scan Allowances') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Root_Cause').setValue('AMS calculó sobre una base de ventas menor');
                    this.addForm.get('concept').setValue('BONIFICACION POR VOLUMEN  SOBRE VENTAS');
                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    this.addForm.get('Claim_Type').setValue('Scan rebate');
                    this.claimHolderValuesMX('Scan rebate');
                    if (this.claimDetails[0].FORMAT.trim() === 'MI BODEGA' ||
                        this.claimDetails[0].FORMAT.trim() === 'BODEGA AURRERA EXPRESS' ||
                        this.claimDetails[0].FORMAT.trim() === 'BODEGA') {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'WM SUPERCENTER') {
                        this.addForm.get('Format').setValue('WMSC');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'SUPERAMA') {
                        this.addForm.get('Format').setValue('WMSU');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'SAMS') {
                        this.addForm.get('Format').setValue('SAMS');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'ECOMMERCE') {
                        this.addForm.get('Format').setValue('E-Commerce');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'DISTRIBUTION') {
                        this.addForm.get('Format').setValue('WMSC');
                    }
                }
                if (this.pageID == 'New Stores') {
                    this.sap_vendor = this.claimDetails[0].SAP_NUMBER;
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Root_Cause').setValue('Aportacion de apertura no incluida o no actualizada en la base de datos de AR.');
                    this.addForm.get('concept').setValue('BONIFICACION POR APERTURA DE TIENDA');
                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    this.addForm.get('Claim_Type').setValue('New Store');
                    this.claimHolderValuesMX('New Store');
                    if (this.claimDetails[0].FORMAT.trim() === 'Bodega Aurrera' || this.claimDetails[0].FORMAT.trim() === 'Bodega Aurrera Express' || this.claimDetails[0].FORMAT.trim() === 'Mi Bodega Aurrera') {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'Supercenter') {
                        this.addForm.get('Format').setValue('WMSC');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'Superama') {
                        this.addForm.get('Format').setValue('WMSU');
                    } else if (this.claimDetails[0].FORMAT.trim() === "Sam's Club") {
                        this.addForm.get('Format').setValue('SAMS');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'E-Commerce') {
                        this.addForm.get('Format').setValue('E-Commerce');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'DISTRIBUTION') {
                        this.addForm.get('Format').setValue('WMSC');
                    }
                }

                if (this.pageID == 'Volume Allowances') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Root_Cause').setValue('AMS calculó sobre una base de ventas o compras menor');
                    this.addForm.get('concept').setValue('BONIFICACION POR VOLUMEN  SOBRE COMPRA');
                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    this.addForm.get('Claim_Type').setValue('Volume rebate');
                    this.claimHolderValuesMX('Volume rebate');
                    if (this.claimDetails[0].FORMAT.trim() === 'Bodega Aurrera' || this.claimDetails[0].FORMAT.trim() === 'Bodega Aurrera Express' || this.claimDetails[0].FORMAT.trim() === 'Mi Bodega Aurrera') {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'Supercenter') {
                        this.addForm.get('Format').setValue('WMSC');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'Superama') {
                        this.addForm.get('Format').setValue('WMSU');
                    } else if (this.claimDetails[0].FORMAT.trim() === "Sam's Club") {
                        this.addForm.get('Format').setValue('SAMS');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'E-Commerce') {
                        this.addForm.get('Format').setValue('E-Commerce');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'DISTRIBUTION') {
                        this.addForm.get('Format').setValue('WMSC');
                    }
                }
                if (this.pageID == 'UN-DEDUCTED COOPS') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Claim_Type').setValue('Undeducted coop');
                    this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].VENDOR_NBR);
                    this.claimHolderValuesMX('Undeducted coop');
                    //this.addForm.get('Dept_Num').setValue(this.claimDetails[0].v);
                    //  this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));
                    this.addForm.get('Root_Cause').setValue('Acuerdos comerciales no incluidos en el sistema AMS luego de ser rechazados inicialmente por el equipo de control de calidad.');
                    this.addForm.get('concept').setValue('BONIFICACION POR VOLUMEN  SOBRE COMPRA');
                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');
                    this.addForm.get('scope').setValue('Proyecto');

                    if (this.claimDetails[0].FORMAT.trim() === 'BODEGA' || this.claimDetails[0].FORMAT.trim() === 'BODEGA AURRERA EXPRESS' || this.claimDetails[0].FORMAT.trim() === 'MI BODEGA') {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'SUPERAMA') {
                        this.addForm.get('Format').setValue('WMSU');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'WALMART.COM') {
                        this.addForm.get('Format').setValue('E-Commerce');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'WM SUPERCENTER') {
                        this.addForm.get('Format').setValue('WMSC');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'ESTACIONAMIENTO' || this.claimDetails[0].FORMAT.trim() === 'HOME OFFICE' || this.claimDetails[0].FORMAT.trim() === 'OTHER' || this.claimDetails[0].FORMAT.trim() === '*') {
                        this.addForm.get('Format').setValue('other');
                    } else if (this.claimDetails[0].FORMAT.trim() === "SAM'S CLUB") {
                        this.addForm.get('Format').setValue('SAMS');
                    }
                } else if (this.pageID == 'MISC') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR_ID);
                    this.supplierName = this.claimDetails[0].SUPPLIER_NAME;
                    this.sap_vendor = this.claimDetails[0].SAP_NUM;
                    this.addForm.get('Claim_Type').setValue('Undeducted coop');
                    this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].VENDOR_NUM);
                    this.claimHolderValuesMX('Undeducted coop');
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);

                    //  this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));
                    this.addForm.get('Root_Cause').setValue('Acuerdos comerciales no incluidos en el sistema AMS luego de ser rechazados inicialmente por el equipo de control de calidad.');
                    this.addForm.get('concept').setValue('BONIFICACION POR VOLUMEN  SOBRE COMPRA');
                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    this.addForm.get('Format').setValue('SAMS');
                    this.addForm.get('Original_Claim_Amt').setValue((this.claimDetails[0].PENDING_DUE).toFixed(2));
                    //  this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));
                }
                else if (this.pageID == 'Advertising') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    this.addForm.get('Claim_Type').setValue('Advertising');
                    this.claimHolderValuesMX('Advertising');
                    //  this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    // this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));
                    this.addForm.get('Root_Cause').setValue('Acuerdos de Publicidad  del plan de marketing(revista)que se ejecutaron y no se contabilizaron en AMS');
                    this.addForm.get('concept').setValue('PUBLICIDAD CIRCULAR');

                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    this.addForm.get('Format').setValue('SAMS');

                    // this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.claimDetails[0].AMOUNT_DUE));
                    // this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(this.originalCLaimAmountfromSession));
                }
                else if (this.pageID == 'Fill Rate') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Claim_Type').setValue('Fill rate');
                    this.claimHolderValuesMX('Fill rate');
                    this.addForm.get('Root_Cause').setValue('Comercial no aplicó el porcentaje de Fill Rate pactado en el adendum de penalidades');
                    this.addForm.get('concept').setValue('Fillrate');

                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    if (this.claimDetails[0].FORMAT.trim() === 'BODEGA' || this.claimDetails[0].FORMAT.trim() === 'BODEGA AURRERA EXPRESS'
                        || this.claimDetails[0].FORMAT.trim() === 'MI BODEGA' || this.claimDetails[0].FORMAT.trim() === 'Bodega') {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'SUPERAMA' || this.claimDetails[0].FORMAT.trim() === 'Superama') {
                        this.addForm.get('Format').setValue('WMSU');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'WALMART.COM'
                        || this.claimDetails[0].FORMAT.trim() === 'E-Commerce' || this.claimDetails[0].FORMAT.trim() === 'ECOMMERCE') {
                        this.addForm.get('Format').setValue('E-Commerce');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'WM SUPERCENTER' || this.claimDetails[0].FORMAT.trim() === 'Supercenter') {
                        this.addForm.get('Format').setValue('WMSC');

                    } else if (this.claimDetails[0].FORMAT.trim() === "SAM'S CLUB" || this.claimDetails[0].FORMAT.trim() === "Sams Club"
                        || this.claimDetails[0].FORMAT.trim() === "SAMS") {
                        this.addForm.get('Format').setValue('SAMS');
                    }
                }
                else if (this.pageID == 'MSI') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Claim_Type').setValue('MSI');
                    this.claimHolderValuesMX('MSI');
                    this.addForm.get('Root_Cause').setValue('El equipo encargado de realizar el cálculo de MSI no tomo el 100% de ventas relacionadas a meses sin interés del período cubierto.');
                    this.addForm.get('concept').setValue('CONTRAPRESTACION MESES SIN INTERESES');

                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    if (this.claimDetails[0].FORMAT.trim() === 'BODEGA' || this.claimDetails[0].FORMAT.trim() === 'Bodega') {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'SUPERAMA' || this.claimDetails[0].FORMAT.trim() === 'Superama') {
                        this.addForm.get('Format').setValue('WMSU');
                    } else if (this.claimDetails[0].FORMAT.trim() === 'WM SUPERCENTER' || this.claimDetails[0].FORMAT.trim() === 'WM Supercenter') {
                        this.addForm.get('Format').setValue('WMSC');
                    }
                }
                else if (this.pageID == 'Low Price') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    // this.addForm.get('Supplier_Name').setValue(this.claimDetails[0].VNDR_NAME);
                    // this.addForm.get('Supplier_Num').setValue(this.claimDetails[0].VNDR_NBR);
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    this.addForm.get('Claim_Type').setValue('Low price');
                    this.claimHolderValuesMX('Low price');
                    this.addForm.get('Root_Cause').setValue('No se aplicó el precio menor por articulo (solo a nivel factura)');
                    this.addForm.get('concept').setValue('EDCL');

                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');
                    if (this.claimDetails[0].SEQ === 0 || this.claimDetails[0].SEQ === 1 || this.claimDetails[0].SEQ === 9) {
                        this.addForm.get('Format').setValue('WMSC');
                    } else if (this.claimDetails[0].SEQ === 2 || this.claimDetails[0].SEQ === 3 || this.claimDetails[0].SEQ === 8) {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].SEQ === 4 || this.claimDetails[0].SEQ === 5) {
                        this.addForm.get('Format').setValue('WMSU');
                    }
                    else if (this.claimDetails[0].SEQ === 6 || this.claimDetails[0].SEQ === 7) {
                        this.addForm.get('Format').setValue('E-Commerce');
                    }
                    else if (this.claimDetails[0].SEQ === 18) {
                        this.addForm.get('Format').setValue('SAMS');
                    }
                }
                else if (this.pageID == 'Shortages') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR);
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    this.addForm.get('Claim_Type').setValue('Shortages');
                    this.claimHolderValuesMX('Shortages');
                    this.addForm.get('Root_Cause').setValue('Se pagó un monto mayor que el que realmente se recibió en mercadería');
                    this.addForm.get('concept').setValue('Faltantes-Precio menor');

                    this.addForm.get('Area_of_Responsibility').setValue('WM Mercaderia');

                    this.addForm.get('scope').setValue('Proyecto');

                    if (this.claimDetails[0].SEQ === 0 || this.claimDetails[0].SEQ === 1 || this.claimDetails[0].SEQ === 9) {
                        this.addForm.get('Format').setValue('WMSC');
                    } else if (this.claimDetails[0].SEQ === 2 || this.claimDetails[0].SEQ === 3 || this.claimDetails[0].SEQ === 8) {
                        this.addForm.get('Format').setValue('WMBO');
                    } else if (this.claimDetails[0].SEQ === 4 || this.claimDetails[0].SEQ === 5) {
                        this.addForm.get('Format').setValue('WMSU');
                    }
                    else if (this.claimDetails[0].SEQ === 6 || this.claimDetails[0].SEQ === 7) {
                        this.addForm.get('Format').setValue('E-Commerce');
                    }
                    else if (this.claimDetails[0].SEQ === 18) {
                        this.addForm.get('Format').setValue('SAMS');
                    }

                }

                else if (this.pageID == 'Markdown') {
                    this.addForm.get('Auditor_Id').setValue(this.claimDetails[0].AUDITOR_NAME);
                    this.addForm.get('Dept_Num').setValue(this.claimDetails[0].DEPT);
                    // this.addForm.get('Claim_Type').setValue('Markdown');
                    this.addForm.get('Claim_Type').setValue('Rollback');
                    this.addForm.get('Supplier_Name').setValue(this.claimDetails[0].VNDR_NAME)
                }
                const adjestclaimAmtnu = this.adjestclaimAmtMX(Number(this.addForm.value.Original_Claim_Amt), Number(this.addForm.value.Adjustments), Number(this.addForm.value.Tax_IVA), Number(this.addForm.value.Tax_EPS));

                // this.changeInputValueMX.pipe(debounceTime(2000)).subscribe((obj) => {
                //   this.adjestclaimAmtMX(obj.claimAmount, obj.adjustment, obj.taxIVA, obj.taxEPS);
                // });
            }

            this.apiservice.getFileDetails(this.appClaimNumber).subscribe((fileList) => {
                for (let i = 0; i < fileList.length; i++) {
                    if (fileList[i].Claim_Package_Flag == null || fileList[i].Claim_Package_Flag == 'N') {
                        this.uploadedFilesList.push(fileList[i].File_Name);
                    }
                }
            });
        });
    }

    ConvertToInt(val) {
        if (val != null) {
            return parseFloat(val);
        }
        else {
            return 0;
        }
    }

    homeIcon() {
        this.router.navigate(['dashboard/' + '/' + this.ccCode]);
    }
    claimHolderValues(claimType) {
        this.addForm
            .get('Claim_Explanation')
            .setValue('Hemos realizado una revisión de nuestros pagos y cobros del ejercicio Año Auditoría ' + this.claimDetails[0].AUDIT_YEAR + ' encontrándose en esta oportunidad diferencias a favor de Walmart Chile, las que presentamos a ustedes' + ' para su revisión. Las diferencias identificadas tienen su origen en  ' + claimType + ' entre Walmart Chile' + '  y su empresa. Adjunto encontrará la documentación de soporte de estas diferencias para su referencia y' + ' revisión.');
    }

    claimHolderValuesMX(claimType) {
        this.addForm
            .get('Claim_Explanation')
            .setValue('  Hemos realizado una revisión de nuestros pagos y cobros del ejercicio Año Auditoría' + this.claimDetails[0].AUDIT_YEAR + ' encontrándose en esta oportunidad diferencias a favor de Walmart Mexico, las que presentamos a ustedes' + ' para su revisión. Las diferencias identificadas tienen su origen en  ' + claimType + ' entre Walmart Mexico' + ' y su empresa. Adjunto encontrará la documentación de soporte de estas diferencias para su referencia y' + ' revisión.');
    }

    createClaimPackage() {
        this.showLoader = true;
        const imageFile = [];
        
        // Determine the claim number
        let claimNumber = this.fileResonseClaimNumber || this.myControl.value;
        if (this.appClaimNumber) {
            claimNumber = this.appClaimNumber;
        }
    
        // Check if claimNumber is available
        if (claimNumber) {
            this.apiservice.getFileDetails(claimNumber).subscribe((result: any) => {
                this.showLoader = false;
                
                // Process file details
                _.each(result, (v) => {
                    if (v.Claim_Package_Flag === null || v.Claim_Package_Flag === 'N') {
                        imageFile.push({
                            file_name: v.File_Name.replace(/\.[^/.]+$/, ''),
                            file: v.Attachment_object,
                            file_id: v.Id
                        });
                    }
                });
    
                // Determine which component to open based on ccCode and pageID
                let componentToOpen;
                if (this.ccCode === 'MX' && this.pageID === 'Volume Allowances') {
                    componentToOpen = CreateVolumePackageComponent;
                } else {
                    componentToOpen = CreatePackageComponent;
                }
    
                // Open the dialog with the appropriate component
                const dialogRef = this.dialog.open(componentToOpen, {
                    hasBackdrop: true,
                    data: {
                        claim_number: claimNumber,
                        claim_info: this.claimDetailById,
                        fileData: imageFile,
                        claimCreationDate: this.dateAudited,
                        country: this.ccCode
                    }
                });
    
                dialogRef.afterClosed().subscribe(() => {
                    this.showLoader = false;
                    this.isDisabled = false;
                });
            });
        } else {
            // Handle the case where claimNumber is not available
            claimNumber = this.fileResonseClaimNumber || this.myControl.value;
    
            // Determine which component to open based on ccCode and pageID
            let componentToOpen;
            if (this.ccCode === 'MX' && this.pageID === 'VOLUMES') {
                componentToOpen = CreateVolumePackageComponent;
            } else {
                componentToOpen = CreatePackageComponent;
            }
    
            // Open the dialog with the appropriate component
            const dialogRef = this.dialog.open(componentToOpen, {
                hasBackdrop: true,
                data: {
                    claim_number: claimNumber,
                    claim_info: this.claimDetailById,
                    fileData: [],
                    country: this.ccCode
                }
            });
    
            dialogRef.afterClosed().subscribe(() => {
                this.isDisabled = false;
            });
        }
    }


    createPayback() {
        //  this.claimData.Original_Claim_Amt = this.pageID;
        //  this.claimData.Claim_Status = this.recordID;
        this.addForm.value.DATE_AUDITED = this.auditYear;
        this.addForm.value.APP_CLAIM_NUMBER = this.appClaimNumber;
        var dataTosend = { data: this.addForm.value, countryCode: this.ccCode };
        const dialogRef = this.dialog.open(CreatePaybackComponent, {
            hasBackdrop: true,
            width: '700px',
            data: { requestId: dataTosend }
        });
        dialogRef.afterClosed().subscribe((result) => { });
    }
    getClaimsnumber(pageID, recordID, ccCode) {
        if (pageID != undefined) {
            let appCLaimNumberCLandMX;
            // this.showLoader = true
            this.apiservice.getClaimsDataByID(pageID, recordID, ccCode).subscribe((response) => {
                this.showLoader = false;
                //  this.apiservice.setSession('claimNumberRecordID', setRecordID);
                this.apiservice.getSession('claimNumber');
                appCLaimNumberCLandMX = this.apiservice.getSession('claimNumber');

                if (appCLaimNumberCLandMX == undefined || appCLaimNumberCLandMX == 'null' || appCLaimNumberCLandMX == null || appCLaimNumberCLandMX == '' || appCLaimNumberCLandMX == 0) {
                    this.showLoader = true
                    this.apiservice.claimNumberGeneration(response[0].AUDIT_YEAR, ccCode).subscribe((response) => {
                        this.appClaimNumber = response.Claim_Number;
                        this.showLoader = false;
                        this.claimTypeIdentifier = 'NEW';
                        this.showLoader = false
                        // this.apiservice.setSession('claimNumber', this.appClaimNumber);
                    });
                    this.dateAudited = this.currentDateTime;
                } else {
                    this.apiservice.oldClaimsData(appCLaimNumberCLandMX, this.ccCode).subscribe((response) => {
                        this.appClaimNumber = appCLaimNumberCLandMX;
                        console.log('response', response);
                        //  debugger;
                        //  this.isDisabled = false;
                        this.dateAudited = response[0].creation_date;
                        this.addForm.get('Auditor_Id').setValue(response[0].Auditor_Id);
                        this.addForm.get('Claim_Type').setValue(response[0].Claim_Type);
                        this.addForm.get('Claim_Status').setValue(response[0].Claim_Status);
                        // this.addForm.get('Root_Cause').setValue(response[0].Root_Cause);
                        this.addForm.get('Claim_Explanation').setValue(response[0].Claim_Explanation);
                        this.addForm.get('Buyer_Id').setValue(response[0].Buyer_Id);
                        this.addForm.get('Buyer_Name').setValue(response[0].Buyer_Name);
                        this.addForm.get('Supplier_Num').setValue(response[0].Supplier_Num);
                        this.addForm.get('Supplier_Name').setValue(response[0].Supplier_Name);
                        this.addForm.get('DMM_Id').setValue(response[0].DMM_Id);
                        this.addForm.get('DMM_Name').setValue(response[0].DMM_Name);
                        this.addForm.get('concept').setValue(response[0].concept);
                        this.addForm.get('scope').setValue(response[0].scope);

                        if (this.ccCode == 'CL') {
                            this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(response[0].Original_Claim_Amt));
                            this.addForm.get('Adjustments').setValue(Math.trunc(response[0].Adjustments));
                            this.addForm.get('Tax_IVA').setValue(Math.trunc(response[0].Tax_IVA));
                            this.addForm.get('Tax_EPS').setValue(Math.trunc(response[0].Tax_EPS));
                        } else {

                            this.addForm.get('Tax_IVA').setValue(this.taxIVAsessionMX);
                            this.addForm.get('Tax_EPS').setValue(this.taxIEPSsessionMX);
                            this.addForm.get('Original_Claim_Amt').setValue(this.originalCLaimAmountfromSessionMX);

                            this.originalClaimAmt = (this.originalCLaimAmountfromSessionMX);
                            if (this.pageID == 'Markdown') { 
                                this.adjustments = this.agreementAmountMarkdown;
                            } else {
                                this.adjustments = 0;
                            }
                            
                            this.taxEps = (this.taxIEPSsessionMX).toFixed(2);
                            this.taxIva = (this.taxIVAsessionMX).toFixed(2);
                            this.adjustedClaimAmt = (this.originalClaimAmt).toFixed(2) + (this.adjustments).toFixed() + (this.taxEps).toFixed(2) + (this.taxIva).toFixed(2);

                            this.addForm.get('Original_Claim_Amt').setValue(this.originalClaimAmt);
                            this.addForm.get('Adjustments').setValue(0);
                            this.addForm.get('Tax_IVA').setValue(this.taxIva);
                            this.addForm.get('Tax_EPS').setValue(this.taxEps);
                            this.addForm.get('Adjusted_Claim_Amt').setValue(this.adjustedClaimAmt);
                            console.log(this.adjustedClaimAmt)

                        }

                        this.addForm.get('Currency').setValue(response[0].Currency);
                        this.addForm.get('action').setValue(response[0].action);

                        //  this.addForm.get('Adjusted_Claim_Amt').setValue(Math.trunc(response[0].Adjusted_Claim_Amt));
                        this.addForm.get('Supplier_Remark').setValue(response[0].Supplier_Remark);
                        this.addForm.get('Auditor_Findings').setValue(response[0].Auditor_Findings);
                        this.addForm.get('Dept_Num').setValue(response[0].Dept_Num);
                        this.addForm.get('Area_of_Responsibility').setValue(response[0].Area_of_Responsibility);
                        this.addForm.get('Time_Period').setValue(response[0].Time_Period);
                        this.addForm.get('Conversion_Likelihood').setValue(response[0].Conversion_Likelihood);
                        this.addForm.get('Additional_Note').setValue(response[0].Additional_Note);
                        this.addForm.get('Format').setValue(response[0].Format);
                    });
                }
                this.setVariables();
            });
        }
    }

    resetClaim() {
        this.showLoader = true;
        this.apiservice.claimNumberGeneration(this.claimDetails[0].AUDIT_YEAR, this.ccCode).subscribe((response) => {
            this.appClaimNumber = '';
            this.appClaimNumber = response.Claim_Number;
            this.showLoader = false;
            this.claimTypeIdentifier = 'NEW';
            this.isDisabled = true;
            this.apiservice.setSession('claimNumber', this.appClaimNumber);
        });
    }

    getAllDropdownValues(CLCode) {
        this.apiservice.getClaimsDropdowns(CLCode, this.pageID).subscribe((response) => {
            this.AllDropdowns = response;

            for (var i = 0; i < this.AllDropdowns.length; i++) {
                if (this.AllDropdowns[i].Lookup_Code == 'CLAIM ESCRIBE') {
                    this.AuditTypeDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'AUDITOR_ID') {
                    this.AuditorIdDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'CLAIM_HOLDER') {
                    this.claimHolderDrowdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'CLAIM STATUS') {
                    this.claimStatusDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'ROOT_CAUSE') {
                    this.rootCauseDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }

                if (this.AllDropdowns[i].Lookup_Code == 'CURRENCY') {
                    this.currency.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'Action') {
                    this.Action.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'AREA_DE_RESPONSABILIDAD') {
                    this.areaOfResponsibility.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'CONVERSION_LIKELIHOOD') {
                    this.conversionLikelihood.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'SAMS_CAT') {
                    this.smasCatDropDown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'SCOPE') {
                    this.scopeDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }

                if (this.AllDropdowns[i].Lookup_Code == 'CONCEPT') {
                    this.conceptDropdown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'ACCOUNT_DEPARTMENT') {
                    this.deptNumDropDown.push(this.AllDropdowns[i].Lookup_Values);
                }
                if (this.ccCode == 'CL') {
                    if (this.pageID == 'VOLUMES' || this.pageID == 'MISC') {
                        if (this.AllDropdowns[i].Lookup_Code == 'FORMAT_TYPE') {
                            this.Format.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                        }
                    } else {
                        if (this.AllDropdowns[i].Lookup_Code == 'FORMAT') {
                            this.Format.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                        }
                    }
                }
                if (this.ccCode == 'MX') {
                    if (this.pageID == 'VOLUMES') {
                        if (this.AllDropdowns[i].Lookup_Code == 'FORMAT_TYPE') {
                            this.Format.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                        }
                    } else {
                        if (this.AllDropdowns[i].Lookup_Code == 'FORMAT') {
                            this.Format.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                        }
                    }
                }
            }
        });
    }

    getDeptNumDropDown() {
        // this.apiservice.getDepartmentNum().subscribe(response => {
        //   this.deptNumDropDown = response;
        // })
    }

    deleteFiles(index, items) {
        if (confirm('Are you sure want to Delete the file?')) {
            this.uplodedFiles.splice(index, 1);
            this.myInputVariable.nativeElement.value = '';
        }
    }

    upload(files: File[]) {
        this.showImgError = false;
        // const fileAcceptType = ['pdf', 'xls', 'xlsx', 'ods', 'ppt', 'pptx', 'txt', 'jpeg', 'png', 'svg', 'gif', 'emlx',
        //   'eml', 'mbox', 'oft', 'bmp', 'pimf', 'jpg']
        const fileAcceptType = ['pdf', 'png', 'jpeg', 'tiff', 'jpg'];
        _.each(files, (v) => {
            const fileName = v.name;
            const fileExt = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
            const fileExtLower = fileExt.toLowerCase();
            const correctFormate = _.find(fileAcceptType, (v) => {
                return v === fileExtLower;
            });
            if (!correctFormate) {
                this.showImgError = true;

                this.addForm.invalid;
            }
            return;
        });
        if (!this.showImgError) {
            this.uplodedFiles = [...this.uplodedFiles, ...files];
        }

        //pick from one of the 4 styles of file uploads below
    }



    // changeTaxMX() {
    //     let value = this.addForm.value;

    //     this.adjestclaimAmtMX(Number(value.Original_Claim_Amt), Number(value.Adjustments), Number(value.Tax_IVA), Number(value.Tax_EPS));
    // }

    setVariables() {
        let value = this.addForm.value;
        this.originalClaimAmt = value.Original_Claim_Amt;
        this.adjustments = value.Adjustments;
        this.taxEps = value.Tax_EPS;
        this.taxIva = value.Tax_IVA;
        this.adjestclaimAmtMX(Number(this.originalClaimAmt), Number(this.adjustments), Number(this.taxIva), Number(this.taxEps));
        this.adjestclaimAmt(Number(this.originalClaimAmt), Number(this.adjustments), Number(this.taxIva), Number(this.taxEps));

        console.log('this.setVariables', this.originalClaimAmt, this.adjustments, this.taxIva, this.taxEps);
    }

    changeTaxMX(key, value) {
        switch (key) {
            case 'Original_Claim_Amt': {
                this.addForm.get('Original_Claim_Amt').setValue(this.removeCommas(value));
                break;
            }
            case 'Adjustments': {
                this.addForm.get('Adjustments').setValue(this.removeCommas(value));
                break;
            }
            case 'Tax_IVA': {
                this.addForm.get('Tax_IVA').setValue(this.removeCommas(value));
                break;
            }
            case 'Tax_EPS': {
                this.addForm.get('Tax_EPS').setValue(this.removeCommas(value));
                break;
            }
        }
        let val = this.addForm.value;
        this.adjestclaimAmtMX(Number(val.Original_Claim_Amt), Number(val.Adjustments), Number(val.Tax_IVA), Number(val.Tax_EPS));
    }

    changeTax(key, value) {
        switch (key) {
            case 'Original_Claim_Amt': {
                this.addForm.get('Original_Claim_Amt').setValue(this.removeCommas(value));
                break;
            }
            case 'Adjustments': {
                this.addForm.get('Adjustments').setValue(this.removeCommas(value));
                break;
            }
            case 'Tax_IVA': {
                this.addForm.get('Tax_IVA').setValue(this.removeCommas(value));
                break;
            }
            case 'Tax_EPS': {
                this.addForm.get('Tax_EPS').setValue(this.removeCommas(value));
                break;
            }
        }
        let val = this.addForm.value;
        this.adjestclaimAmt(Number(val.Original_Claim_Amt), Number(val.Adjustments), Number(val.Tax_IVA), Number(val.Tax_EPS));

        // this.changeInputValue.next({
        //     claimAmount: Number(this.addForm.value.Original_Claim_Amt),
        //     adjustment: Number(this.addForm.value.Adjustments),
        //     taxIVA: Number(this.addForm.value.Tax_IVA),
        //     taxEPS: Number(this.addForm.value.Tax_EPS)
        // });
    }

    taxCalulate(claimAmt, adjustment) {
        if (claimAmt === 0 && adjustment === 0) {
            return 0;
        } else {
            const calValu = ((claimAmt + adjustment) * 0.19).toFixed(0);
            this.addForm.get('Tax_IVA').setValue(calValu);
            return calValu;
        }
    }

    adjestclaimAmt(claimAmt, adjustment, taxIVA, taxEPS) {
        if (claimAmt === 0 && adjustment === 0 && taxIVA === 0 && taxEPS === 0) {
            return 0;
        } else {
            const calValu = (claimAmt + adjustment + taxIVA + 0);
            this.addForm.get('Adjusted_Claim_Amt').setValue(calValu);
            return calValu;
        }
    }

    adjestclaimAmtMX(claimAmt, adjustment, taxIVA, taxEPS) {
        if (claimAmt === 0 && adjustment === 0 && taxIVA === 0 && taxEPS === 0) {
            return 0;
        } else {
            const calValu = (claimAmt + adjustment + taxIVA + taxEPS);
            this.adjustedClaimAmt = calValu;
            this.addForm.get('Adjusted_Claim_Amt').setValue(calValu);
            return calValu;
        }
    }

    onSubmit() {
        this.showLoader = true;

        this.addForm.value.APP_CLAIM_NUMBER = this.appClaimNumber;
        this.addForm.value.Audit_year = this.auditYear;

        if (this.claimDetails[0] == undefined) {
            this.addForm.value.DATE_AUDITED = this.claimDetailById.creation_date;
            // this.addForm.value.SUPPLIER_NAME = this.claimDetailById.Supplier_Name;
            this.addForm.value.Supplier_Name = this.claimDetailById.Supplier_Name;
            this.addForm.value.Audit_Type = this.claimDetailById.Audit_Type;
            this.addForm.value.Record_Id = '';
        } else {
            this.addForm.value.Audit_Type = this.pageID;
            this.addForm.value.Record_Id = this.claimDetails[0].RECORD_ID;
            if (this.ccCode == 'MX') {
                this.addForm.value.Supplier_Name = this.claimDetails[0].VENDOR_NAME;
            } else {
                this.addForm.value.Supplier_Name = this.claimDetails[0].SUPPLIER_NAME;
            }
        }
        this.addForm.value.country = this.ccCode;
        // his.claimDetails
        //  this.multipleRecordIds = this.apiservice.getSession('recordIDs');

        if (this.claimDetails.length == 0) {
        } else {
            //  this.addForm.value.Record_Id = this.multipleRecordIds.replace(/[\[\]']+/g, '');
        }

        this.addForm.value.user_id = this.username;
        // this.pageID, this.recordID
        let sendclaimType;
        if (this.claimTypeIdentifier == 'NEW') {
            sendclaimType = 'NEW';
        } else {
            sendclaimType = 'OLD';
        }
        if (this.multipleRecordIds != null && this.multipleRecordIds != undefined && this.multipleRecordIds != '') {
            this.addForm.value.Record_Id = this.multipleRecordIds.replace(/[\[\]']+/g, '');
        }
        if (this.ccCode == 'MX' && this.pageID == 'MISC') {
            this.addForm.value.Supplier_Name = this.claimDetails[0].SUPPLIER_NAME;
        }
        if (this.ccCode == 'MX' && this.pageID == 'Markdown') {
            this.addForm.value.Supplier_Name = this.claimDetails[0].VNDR_NAME;
            this.addForm.value.Adjusted_Claim_Amt = this.claimDetails[0].TOTAL_DUE;

        }
       // this.addForm.value.Adjusted_Claim_Amt = this.adjustedClaimAmt.toFixed(2);
        this.apiservice.sendClaimsData(this.addForm.value, this.ccCode, sendclaimType).subscribe((response) => {


            if (response.code == 500) {
                this.openSnackBar('Failed to add claim', 'ok');
                this.showLoader = false;
            } else {
                if (response[0].response == "No such claim number exists in claim table") {
                    this.openSnackBar('Failed to add claim', 'ok');
                    this.showLoader = false;
                }
                else {
                    this.isDisabled = false;
                    this.claimDetailById = this.addForm.value;
                    this.claimDetailById.APP_CLAIM_NUMBER = this.appClaimNumber;
                    this.fileResonseClaimNumber = response[0].response;
                    this.claimTypeIdentifier = 'OLD';

                    this.appClaimNumber = this.fileResonseClaimNumber;
                    // this.fileUploadfunction(response[0].response)
                    this.uploadAndProgress(this.uplodedFiles, this.fileResonseClaimNumber);
                }
            }
        });
    }

    openSnackBar(message: string, action: string) {
        //  duration: 3000
        // this._snackBar.open(message, action);
        this._snackBar.open(message, action, {
            duration: 3000,
            // horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
        });
    }

    uploadAndProgress(files: File[], claimID) {
        if (this.showImgError == false) {
            var formData = new FormData();
            Array.from(files).forEach((f) => formData.append('file', f));

            this.http.post(this.domain + '/FileUpload/' + claimID + '/' + this.username.replace(/\s/g, ''), formData, { reportProgress: true, observe: 'events' }).subscribe((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.percentDone = Math.round((100 * event.loaded) / event.total);
                } else if (event instanceof HttpResponse) {
                    this.uploadSuccess = true;
                }
            });
            this.openSnackBar('Data saved successfully', 'Ok');
            this.showLoader = false;
        } else {
            this.myInputVariable.nativeElement.value = '';
            this.showImgError = false;
            alert('Please upload correct file format');
        }
    }

    /**
     *  Show pop-up on enter
     * @param  {} e
     */
    searchOnEnter(e) {
        this.genericSearchPopup(e.target.value);
    }

    /**
     *  Generic function for pop-up functionality
     * @param  {} value
     */
    genericSearchPopup(value) {
        let searchValue = value;
        if (searchValue.length === 9) {
            searchValue ? this._filter(searchValue) : this.options.slice();
            const selectedValue: any = this.options.filter((option: any) => {
                if (option.APP_CLAIM_NUMBER === searchValue) {
                    return true;
                } else {
                    false;
                }
            });
            this.showPopUp(selectedValue);
        } else {
            alert('Please enter a valid Claim Number');
        }
    }

    /**
     *  Show pop-up on click mat-option
     * @param  {} value
     */
    searchOnClick(value) {
        this.genericSearchPopup(value);
    }
    getAllClaimData(countyrCode) {
        this.apiservice.getAllClaimsData(countyrCode).subscribe((result) => {
            this.options = result;

            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map((value) => (value ? this._filter(value) : this.options.slice()))
            );
        });
    }
    private _filter(value: string): string[] {
        const filterValue = value;
        const selectedValue: any = this.options.filter((option: any) => {
            if (option.APP_CLAIM_NUMBER === filterValue) {
                return true;
            } else {
                false;
            }
        });
        return selectedValue;
    }

    showPopUp(dataValues) {
        this.uploadedFilesList = [];
        let dialogRef = this.dialog.open(TableModalComponent, {
            width: '100%',
            data: dataValues
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.claimDetailById = dataValues[0];
            console.log('dataValues', dataValues[0]);
            this.isDisabled = false;
            this.appClaimNumber = dataValues[0].APP_CLAIM_NUMBER;
            this.dateAudited = dataValues[0].creation_date;
            this.auditYear = dataValues[0].Audit_year;

            this.apiservice.getFileDetails(this.appClaimNumber).subscribe((resultFiles) => {
                for (let i = 0; i < resultFiles.length; i++) {
                    if (resultFiles[i].Claim_Package_Flag == null || resultFiles[i].Claim_Package_Flag == 'N') {
                        this.uploadedFilesList.push(resultFiles[i].File_Name);
                    }
                }
            });
            this.multipleClaimsDetails(this.multipleRecordIds);
            if (this.ccCode == 'MX') {
                if (this.pageID != 'Scan Allowances') {
                    this.sidePRDetails = 'none';
                }
            }
            this.selectedPRDetailsByClaimNumber(this.ccCode, dataValues[0].Audit_Type, this.appClaimNumber);

            this.addForm.get('Auditor_Id').setValue(dataValues[0].Auditor_Id);
            this.addForm.get('Claim_Type').setValue(dataValues[0].Claim_Type);
            this.addForm.get('Claim_Status').setValue(dataValues[0].Claim_Status);
            this.addForm.get('Root_Cause').setValue(dataValues[0].Root_Cause);
            this.addForm.get('Claim_Explanation').setValue(dataValues[0].Claim_Explanation);
            this.addForm.get('Buyer_Id').setValue(dataValues[0].Buyer_Id);
            this.addForm.get('Buyer_Name').setValue(dataValues[0].Buyer_Name);
            this.addForm.get('Supplier_Num').setValue(dataValues[0].Supplier_Num);
            this.addForm.get('Supplier_Name').setValue(dataValues[0].Supplier_Name);
            this.addForm.get('DMM_Id').setValue(dataValues[0].DMM_Id);
            this.addForm.get('DMM_Name').setValue(dataValues[0].DMM_Name);
            this.addForm.get('concept').setValue(dataValues[0].concept);
            this.addForm.get('scope').setValue(dataValues[0].scope);
            this.addForm.get('Format').setValue(dataValues[0].Format);

            this.addForm.get('Currency').setValue(dataValues[0].Currency);
            this.addForm.get('action').setValue(dataValues[0].action);

            this.addForm.get('Supplier_Remark').setValue(dataValues[0].Supplier_Remark);
            this.addForm.get('Auditor_Findings').setValue(dataValues[0].Auditor_Findings);
            this.addForm.get('Dept_Num').setValue(dataValues[0].Dept_Num);
            this.addForm.get('Area_of_Responsibility').setValue(dataValues[0].Area_of_Responsibility);
            this.addForm.get('Time_Period').setValue(dataValues[0].Time_Period);
            this.addForm.get('Conversion_Likelihood').setValue(dataValues[0].Conversion_Likelihood);
            this.addForm.get('Additional_Note').setValue(dataValues[0].Additional_Note);
            this.showPrDetails = this.claimDetails.length != 0 ? true : false;
            if (this.ccCode == 'CL') {
                this.addForm.get('Original_Claim_Amt').setValue(Math.trunc(dataValues[0].Original_Claim_Amt));
                this.addForm.get('Adjustments').setValue(Math.trunc(dataValues[0].Adjustments));
                this.addForm.get('Tax_IVA').setValue(Math.trunc(dataValues[0].Tax_IVA));
                this.addForm.get('Tax_EPS').setValue(Math.trunc(dataValues[0].Tax_EPS));
             //   this.addForm.get('Adjusted_Claim_Amt').setValue(Math.trunc(dataValues[0].Adjusted_Claim_Amt));
             this.adjustedClaimAmt = Number(dataValues[0].Original_Claim_Amt) + Number(dataValues[0].Adjustments) + Number(dataValues[0].Tax_EPS) + Number(dataValues[0].Tax_IVA);
                this.addForm.get('Adjusted_Claim_Amt').setValue(this.adjustedClaimAmt);
            } else {
                this.originalClaimAmt = (dataValues[0].Original_Claim_Amt);
                this.adjustments = (dataValues[0].Adjustments);
                this.taxEps = (dataValues[0].Tax_EPS);
                this.taxIva = (dataValues[0].Tax_IVA);
              //  this.adjustedClaimAmt = dataValues[0].Adjusted_Claim_Amt;
                this.adjustedClaimAmt = Number(dataValues[0].Original_Claim_Amt) + Number(dataValues[0].Adjustments) + Number(dataValues[0].Tax_EPS) + Number(dataValues[0].Tax_IVA);

                this.addForm.get('Original_Claim_Amt').setValue(this.originalClaimAmt);
                this.addForm.get('Adjustments').setValue(this.adjustments);
                this.addForm.get('Tax_IVA').setValue(this.taxIva);
                this.addForm.get('Tax_EPS').setValue(this.taxEps);
                console.log(this.adjustedClaimAmt)
                this.addForm.get('Adjusted_Claim_Amt').setValue(this.adjustedClaimAmt);
            }
        });
    }

    viewFiles(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file); // read file as data url

        reader.onload = (event) => {
            // called once readAsDataURL is completed
            this.url = event.target.result;
        };
    }

    selectedPRDetailsByClaimNumber(ccCode, audittype, claimID) {
        this.showLoader = true;
        this.apiservice.getSelectedPRdetails(ccCode, audittype, claimID).subscribe((result) => {
            this.showLoader = false;
            this.claimDetails = result;
            this.multipleRecordDetails = result;
            console.log(' this.multipleRecordDetails', this.multipleRecordDetails);
            this.pageID = audittype;
            this.showPrDetails = this.claimDetails.length != 0 ? true : false;
        });
    }

    hideSidePRDetails() {
        if (this.ccCode == 'MX') {
            if (this.pageID == 'Advertising' || this.pageID == 'New Stores' ||
                this.pageID == 'Volume Allowances' || this.pageID == 'UN-DEDUCTED COOPS'
                || this.pageID == 'MISC' || this.pageID == 'Fill Rate' || this.pageID == 'MSI'
                || this.pageID == 'Low Price' || this.pageID == 'Shortages') {
                this.sidePRDetails = 'none';
            }
        }
    }

    removeCommas(number) {
        if (number) {
            return number.toString().replace(/,/g, '');
        }
    }

    addCommas(number) {
        if (number) {
            return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
        }
    }
}
