import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(@Inject(OKTA_AUTH) private _oktaAuth: OktaAuth) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        console.log('Intercepted request:', request.url); 

        return from(this.addAuthHeaderToAllowedOrigins(request)).pipe(
            switchMap(req => {
                console.log('Request after adding auth header:', req); 
                return next.handle(req);
            })
        );
    }

    private async addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): Promise<HttpRequest<unknown>> {
        let req = request;

       
        const allowedOrigins = ['https://ppatool-api.genpact.com/']; 
        const isAllowedOrigin = allowedOrigins.some(origin => request.url.startsWith(origin));

        console.log('Actual Request URL:', request.url); 

        if (isAllowedOrigin) {
            console.log('Request URL matches allowed origin, processing token.');

            try {
               
                const isAuthenticated = await this._oktaAuth.isAuthenticated();
                if (!isAuthenticated) {
                    console.warn('User is not authenticated. No token will be attached.');
                    return req;  
                }

                
                const authToken = await this._oktaAuth.getAccessToken();
                console.log('Auth Token retrieved:', authToken);  

                if (authToken) {
                    
                    req = request.clone({
                        setHeaders: { Authorization: `Bearer ${authToken}` }
                    });
                    console.log('Token attached to request header:', req.headers.get('Authorization'));
                } else {
                    console.warn('No auth token found.');
                }
            } catch (error) {
                console.error('Error retrieving token:', error);  
            }
        } else {
            console.warn('Request URL does not match allowed origin. No token will be attached.');
        }

        return req;  
    }
}
