import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthState } from '@okta/okta-auth-js';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { User } from '../../_models/user';
import { apiService } from 'src/app/_services/app.service';

import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../_services';
import { LoginUser, Role } from '../../_models';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    public name$!: Observable<string>;
    public mail$!: Observable<string>;
    public OHRID$!: Observable<string>;

    //new Login
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    accept = false;

    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private apiservice: apiService, private authenticationService: AuthenticationService, private _oktaAuthStateService: OktaAuthStateService, private router: Router) {
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    public ngOnInit(): void {
        this.onsubmit();
    }

    onsubmit() {
        let country = localStorage.getItem('country');
        this.name$ = this._oktaAuthStateService.authState$.pipe(
            filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
            map((authState: AuthState) => authState.idToken?.claims.name ?? '')
        );

        this.mail$ = this._oktaAuthStateService.authState$.pipe(
            filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
            map((authState: AuthState) => authState.idToken?.claims.email ?? '')
        );

        this.OHRID$ = this._oktaAuthStateService.authState$.pipe(
            filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
            map((authState: AuthState) => authState.idToken?.claims.preferred_username ?? '')
        );

        let Gohrid = '';
        let mail = '';
        let name = '';

        this.name$.subscribe((d) => {
            name = d;
        });
        this.OHRID$.subscribe((d) => {
            Gohrid = d;
        });
        this.mail$.subscribe((d) => {
            mail = d;
        });

        let user = Gohrid.replace('@GENPACT.COM', '');

        let userinfo = {
            username: name,
            email: mail,
            OHRID: user.replace('@genpact.com', '')
        };

        localStorage.setItem('userinfo', JSON.stringify(userinfo));

        this.authenticationService.login().subscribe(
            (data) => {
                console.log(data);
                if (data.length > 0) {
                    this.router.navigate(['/pages/dashboard/' + country]);
                } else {
                    this.loading = false;
                    this.accept = true;
                }
            },

            (error) => {
                this.error = error;
                this.loading = false;
                this.accept = true;
            }
        );


    }
}
