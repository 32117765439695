import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, RowSelectedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridAngular } from 'ag-grid-angular';
import { AuditServiceService } from 'src/app/_services/audit-service.service';
import { apiService } from 'src/app/_services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AssignAuditorComponent } from '../popup/assign-auditor/assign-auditor.component';
import { MatDialog } from '@angular/material/dialog';
import { AddRowComponent } from '../popup/add-row/add-row.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    auditTableType: any;
    auditTableData: any;
    auditTypeID: any;
    ID: any;
    auditDropDown: any;
    reportDropDown: any;
    selecteddata: any;
    clCode: any;
    public rowSelection = 'multiple';
    private gridApi!: GridApi;
    public rowData!: any[];
    dummyData: any;

    constructor(
        private apiservice: apiService,
        private http: HttpClient,
        private auditService: AuditServiceService,
        public activatedRoute: ActivatedRoute,
        // private spinnerService: Ng4LoadingSpinnerService,
        public dialog: MatDialog,
        public router: Router,
        public translate: TranslateService
    ) {
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
        // this.rowData = this.http.get<any[]>('https://ppatool-api.genpact.com/getAuditDataByAuditType/LOGRO');
    }

    // columnDefs: ColDef[] = [
    //   {
    //     headerName: 'Change User',
    //     field: 'CHANGE_USER',
    //     minWidth: 163,
    //     headerCheckboxSelection: true,
    //     headerCheckboxSelectionFilteredOnly: true,
    //     checkboxSelection: true,
    //     filter: 'agTextColumnFilter',
    //     filterParams: filterParams,
    //     suppressSizeToFit: true,
    //     width: 178,

    //   },
    //   // { field: 'CHANGE_USER', sortable: true, filter: true,checkboxSelection:true },
    //   { headerName: 'Audit Year', field: 'AUDIT_YEAR', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Release No', field: 'RELEASE_NUM', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'RUT', field: 'RUT', sortable: true, filter: true, filterParams: filterParams, width: 100 },
    //   { headerName: 'Supplier Name ', field: 'SUPPLIER_NAME', sortable: true, filter: true, filterParams: filterParams, width: 200 },
    //   { headerName: 'Supplier No', field: 'SUPPLIER_NUM', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Allow Type', field: 'ALLOW_TYPE', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Deal Agreement No', field: 'DEAL_AGREEMENT_NUM', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Format Type', field: 'FORMAT_TYPE', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Start Date', field: 'START_DATE', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'End Date', field: 'END_DATE', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Account Dept', field: 'ACCOUNT_DEPARTMENT', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Legacy Category', field: 'LEGACY_CATEGORY', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Category', field: 'CATEGORY', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Private Label', field: 'PRIVATE_LABEL', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Year Volume ', field: 'YEAR_VOLUME', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Volume', field: 'VOLUME', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Earned', field: 'EARNED', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Amt Received', field: 'AMOUNT_RECEIVED', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Fixed Amt Agreement', field: 'FIXED_AMOUNT_AGREEMENT', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Debited Amt', field: 'DEBITED_AMOUNT', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Auditor Id', field: 'AUDITOR_ID', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Date Audited', field: 'DATE_AUDITED', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'QR status', field: 'QR_STATUS', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Agreement No', field: 'AGREEMENT_NUMBER', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'App Claim No', field: 'APP_CLAIM_NUMBER', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Comments', field: 'COMMENTS', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Flag', field: 'FLAG', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Record Start Date', field: 'RECORD_START_DATE', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Record End Date', field: 'RECORD_END_DATE', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Pipeline Run Id', field: 'PIPELINE_RUN_ID', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Load Start Time', field: 'LOAD_START_TIME', sortable: true, filter: true, filterParams: filterParams, width: 130 },
    //   { headerName: 'Load End Time', field: 'LOAD_END_TIME', sortable: true, filter: true, filterParams: filterParams, width: 130 },

    // ];

    ngOnInit() {
        // this.spinnerService.show();
        this.SetLang();
        this.clCode = this.activatedRoute.snapshot.params['CL'];
        this.auditTypeID = this.activatedRoute.snapshot.params['ID'];
        this.loadAuditTypeValues(this.clCode);
        this.reportDropDown = JSON.parse(localStorage.getItem('report_lookup'));
    }

    homeIcon() {
        this.router.navigate(['dashboard/' + '/' + this.clCode]);
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }

    loadAuditTypeValues(CLCode) {
        this.apiservice.getAuditType(CLCode).subscribe((response) => {
            this.auditDropDown = response;
            this.selecteddata = this.auditTypeID;
        });
    }

    selectedValue(auditTypeId: any) {
        // this.http
        //   .get<any[]>('https://ppatool-api.genpact.com/getAuditDataByAuditType/' + auditTypeId)
        //   .subscribe((data) => (this.rowData = data));
        // this.router.navigate(['assign/' + this.clCode + "/" + auditTypeId]);
        //this.rowData = this.http.get<any[]>('https://ppatool-api.genpact.com/getAuditDataByAuditType/LOGRO');
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;

        this.http.get<any[]>('https://ppatool-api.genpact.com/getAuditDataByAuditType/' + this.auditTypeID).subscribe((data) => (this.rowData = data));
    }

    onBtnExport() {
        this.gridApi.exportDataAsCsv();
    }
}

var filterParams = {
    suppressAndOrCondition: false,
    filterOptions: ['equals', 'contains', 'startsWith', 'endsWith', 'notEqual', 'notContains', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
    buttons: ['reset', 'apply'],
    closeOnApply: true,
    defaultJoinOperator: 'OR'
};
